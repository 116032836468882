.changePassword-section-container {
  padding: 1rem;
  border: 1px solid $field-background;
  

  .changePassword-container {
    padding: 1rem;
    @media (max-width: $sm-breakpoint) {
      padding: 0rem !important;
    }
    .content {
      font-size: $font-size-sm;
    }

    .field-container {
      max-width: 31.25rem;
      width: 100%;

      .field-heading {
        font-weight: $font-weight-medium;
        font-size: $font-size-base;
        color: $black;

        @media (max-width: $sm-breakpoint) {
          font-size: $font-size-sm;
        }
      }
    }
  }
}