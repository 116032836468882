.dialogBox {
  .MuiDialog-paper {
    border-radius: 3.125rem 0 3.125rem 0 !important;
    min-width: 40% !important;
    width: 100%;
    padding: 1rem 2rem;

    @media (max-width: $sm-breakpoint) {
      min-width: 95% !important;

    }
  }

  .dialog-header {
    display: flex;
    justify-content: center;
    align-items: center;



    .greet-icon-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .greet-icon {
        border-radius: 50%;
        height: 6rem;
        width: 6rem;

        @media (max-width: $sm-breakpoint) {
          height: 5.5rem;
          width: 5.5rem;
        }

        .icon {
          width: 90%;
          height: 90%;
          object-fit: contain;
        }
      }

    }

    .dialog-content {

      .dialog-heading {
        color: $light-red !important;
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;

        @media (max-width: $sm-breakpoint) {
          font-size: $font-size-base;
        }
      }

      .dialog-subheading {
        font-size: $font-size-sm;
        color: $black;
        text-transform: capitalize;


        @media (max-width: $sm-breakpoint) {
          font-size: $font-size-sm;
        }
      }
    }
  }

  .dialogTitle {
    color: $light-red !important;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;


    @media (max-width: $sm-breakpoint) {
      font-size: $font-size-base;
    }
  }

  .dialog-discription {
    font-size: $font-size-sm;
  }


  .sender-info-tag {
    .sender-info-name-tag {
      width: 2.1875rem;
      height: 2.1875rem;
      border-radius: 50%;
      border: 1px solid $field-background;
      color: $primary;
      font-size: $font-size-sm;
      text-transform: capitalize;

      .avatar {
        width: 100%;
        height: 100%;
      }
    }

    .sender-info-heading {
      font-size: .75rem;
      color: $primary;
      text-transform: capitalize;
    }

    .sender-info {
      font-size: .75rem;
      color: $gray;
      text-transform: capitalize;
    }
  }
}