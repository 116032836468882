.profile-header-container {
  height: 5rem;
  display: flex;
  align-items: center;
  background: $linear-background;
  padding-left: 1rem;

  @media (max-width: $sm-breakpoint) {
    height: 3.125rem;
  }

  .profile-heading {
    font-size: $font-size-lg;
    font-weight: $font-weight-medium;
    color: $white;
    text-transform: capitalize !important;

    @media (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }


}