.profile-section {
  margin-top: ($header-height + $header-height-sm) !important;
  min-height: calc(100vh - ($header-height + $header-height-sm + $footer-height + 56px));

  .profileImg-container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .profileimgHead {
      width: 100% !important;
      margin-left: 2rem;
      font-weight: $font-weight-bold;
      font-size: $font-size-lg;
    }
  }

  .profile-section-container {
    padding: 1rem ;
    border: 1px solid $field-background;

    .profile-container {
      .form-heading {
        font-size: 1rem;
        color: $primary;
        font-weight: bold;

        @media (max-width: $sm-breakpoint) {
          display: none;
        }
      }

      .field-container {
        max-width: 31.25rem;
        width: 100%;
      }


      .profile-image {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .sidebox-img-container {
          width: 13.375rem;
          height: 13.375rem;
          border: 2px solid $primary;
          position: relative;
          border-radius: 50%;
          padding: 0.5rem;

          @media (max-width: $sm-breakpoint) {
            width: 10.375rem;
            height: 10.375rem;
            border: 1px solid $primary;
          }

          .profileImg {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .image-upload {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: lowercase !important;
          cursor: pointer;
          font-size: $font-size-sm !important;
          color: $primary !important;
  
        }
      }
    }

  }

  .update-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    @media (max-width: $sm-breakpoint) {
      justify-content: center;
    }
  }
}