.fullpageLoader {
  width: 100vw;
  height: 100svh;
  position: fixed;
  background: $linear-background;
  z-index: 1;

  .loader-container {
    width: 7.75rem;
    height: 7.75rem;
    flex-direction: column;

    @media (max-width: $sm-breakpoint) {
      width: 6rem;
      height: 6rem;
    }

    .spinner {
      width: 100%;
      height: 100%;
      border: .25rem solid;
      border-color: $white transparent $white transparent;
      border-radius: 50%;
      animation: spin-anim 1.2s linear infinite;
    }

    @keyframes spin-anim {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .logo-container {
      width: 4.5rem;
      height: 4.5rem;

      @media (max-width: $sm-breakpoint) {
        width: 3rem;
        height: 3rem;
      }

      .logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}