.footer {
  margin-top: 3.125rem;
  width: 100%;
  height: $footer-height;
  background: $linear-background;

  @media (max-width: $sm-breakpoint) {

    height: 2.5rem !important;
  }

  .footer-container {
    height: $footer-height;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $sm-breakpoint) {

      justify-content: center;
      height: 40px !important;
    }

    .footer-name {
      color: $white;
      font-size: $font-size-sm
    }
  }
}