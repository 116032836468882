.greet-sidebox {
  min-height: 30rem;
  width: 100%;
  background: $linear-background;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  @media (max-width: $sm-breakpoint) {
    min-height: 21rem;
    justify-content: center;
    padding: 1rem;
  }

  @media (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
    padding: 1rem;
  }

  .sidebox-img-container {
    margin-top: 3.125rem;
    width: 9.375rem;
    height: 9.375rem;
    border: 2px solid white;
    position: relative;
    border-radius: 50%;

    @media (max-width: $sm-breakpoint) {
      width: 5.375rem;
      height: 5.375rem;
      border: 1px solid white;
    }

    .image-upload {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $field-background;

      .label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .edit-icon {
          font-size: 1rem;
        }
      }
    }


    .profileImg {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .sidebox-button-container {
    width: 100%;
  }

  .sidebox-text {
    color: $white;
    font-size: $font-size-lg;
    text-transform: capitalize;
    text-align: center;

    @media (max-width: $sm-breakpoint) {
      font-size: $font-size-base;
    }
  }
}