.loginPage {
    height: 100svh;
    overflow: hidden !important;

    .bg-effect {
        height: 100%;
        background-color: $white;

        @media (max-width: $md-breakpoint) {
            height: 92%;
            width: 100%;
            position: absolute;
            bottom: 0;
            border-radius: 50px 50px 0 0;
        }

        .loginContent {
            height: 100svh;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding-top: 6rem;
            padding-bottom: 1rem;
            overflow: auto;

            @media (max-width: $md-breakpoint) {
                margin-top: 2rem;
                height: 88svh;
                padding: 1rem;
            }
        }
    }


    .loginSideBox {
        height: 100svh;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @media (max-width: $md-breakpoint) {
            height: 15svh;
            background-image: none;
            background-color: $primary;
        }

        .logo-container {
            width: 9rem;
            height: 9rem;

            @media (max-width: $md-breakpoint) {
                display: none;
            }

            .logo {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .loginContent {
        .content-container {
            max-width: 31.25rem;
            width: 100%;

            .link-content {
                text-align: center;
                color: $gray;
                font-size: $font-size-base;
                font-weight: 500;

                @media (max-width: $sm-breakpoint) {
                    font-size: $font-size-sm;
                }

                .redirect-link {
                    color: $primary;
                    font-size: $font-size-base;

                    @media (max-width: $sm-breakpoint) {
                        font-size: $font-size-sm;
                    }
                }
            }

            .login_links {
                text-decoration: none;
                color: $primary;
                display: flex;
                align-items: center;
                font-size: $font-size-base;

                @media (max-width: $sm-breakpoint) {
                    font-size: $font-size-sm;
                }
            }

            .content-title {
                font-size: $h3-font-size;
                font-weight: $font-weight-bold;
                color: $black;

                @media (max-width: $sm-breakpoint) {
                    font-size: $font-size-lg;
                }
            }

            .content-text {
                font-size: $font-size-base;
                color: $gray;

                @media (max-width: $sm-breakpoint) {
                    font-size: $font-size-sm;
                }
            }
        }
    }

}