.loginPage {
    .successfull-screen {
        height: 100svh;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $sm-breakpoint) {
            min-height: calc(100svh - 4.5rem);
            height: 100%;
        }

        @media (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
            min-height: 70svh;
            height: 100%;
        }

        .content-container {
            max-width: 31.25rem;
            width: 100%;
        }

        .content-title {
            font-size: $h3-font-size;
            font-weight: $font-weight-bold;
            color: $black;
            text-align: center;

            @media (max-width: $sm-breakpoint) {
                font-size: $font-size-lg;
            }

        }

        .content-text {
            font-size: $font-size-base;
            color: $gray;
            text-align: center;

            @media (max-width: $sm-breakpoint) {
                font-size: $font-size-sm;
            }
        }
    }

}