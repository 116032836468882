// Margin Classes

//margin-5px

.mt-5 {
  margin-top: 0.3125rem !important;
}

.mr-5 {
  margin-right: 0.3125rem !important;
}

.ml-5 {
  margin-left: 0.3125rem !important;
}

.mb-5 {
  margin-bottom: 0.3125rem !important;
}

// margin-10px

.mr-10 {
  margin-right: 0.625rem !important;
}

.ml-10 {
  margin-left: 0.625rem !important;
}

.mt-10 {
  margin-top: 0.625rem !important;
}

.mb-10 {
  margin-bottom: 0.625rem !important;
}


//margin-18px 

.mt-18 {
  margin-top: 1.125rem !important;
}

.mr-18 {
  margin-right: 1.125rem !important;
}

.mb-18 {
  margin-bottom: 1.125rem !important;
}

.ml-18 {
  margin-left: 1.125rem !important;
}




//margin-30px
.mt-30 {
  margin-top: 1.875rem !important;
}

.mb-30 {
  margin-bottom: 1.875rem !important;
}

.mr-30 {
  margin-right: 1.875rem !important;
}

.ml-30 {
  margin-left: 1.875rem !important;
}

//margin-50px

.mt-50 {
  margin-top: 3.125rem !important;
}

.mb-50 {
  margin-bottom: 3.125rem !important;
}

.ml-50 {
  margin-left: 3.125rem !important;
}

.mr-50 {
  margin-right: 3.125rem !important;
}

//screen-container

.screen-container {
  min-height: 31.5rem;
  height: 100%;

  @media screen and (max-width: $sm-breakpoint) {
    min-height: 25.5rem;
  }
}

.text-center {
  text-align: center !important;
}

.no_internet {
  width: 100vw;
  height: 100vh;
  background-color: $white;

  .no-internet-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg {
      width: 100%;
      font-size: 4.5rem !important;
      display: flex;
      justify-content: center;
      color: red;
    }
  }
}

.primary {
  color: $primary !important;
}

.resend-otp-container {
  width: 100%;
  display: flex;
  justify-content: center;

  .resendOTP {
    text-align: center;
    text-transform: capitalize !important;
    color: $primary;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.greeting-animation {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .gif {
    height: 80%;
    width: 40%;

    @media (max-width: $sm-breakpoint) {
      width: 100%;
    }

    @media (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
      width: 80%;
    }
  }

}