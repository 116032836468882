.greet-card {
  cursor: pointer;
  border: 1px solid $field-background;
  width: 100%;
  min-height: 10.75rem;
  padding: 1rem;
  transition: 0.2s ease-in-out;
  background-color: $white;

  &:hover {
    transform: scale(1.01);
    box-shadow: $box-shadow;
  }

  .heading-date-container {
    .sender-date {
      font-size: .75rem;
      color: $gray;
      text-transform: capitalize;

      @media (max-width: $sm-breakpoint) {
        font-size: .625rem;
      }
    }
  }

  .greet-icon-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: $sm-breakpoint) {
      align-items: center;
      justify-content: flex-start;
    }
  }

  .greet-icon {
    border-radius: 50%;
    height: 6rem;
    width: 6rem;

    @media (max-width: $sm-breakpoint) {
      margin-right: .625rem;
      height: 4rem;
      width: 4rem;
    }

    .icon {
      width: 90%;
      height: 90%;
      object-fit: contain;
    }

  }

  .greet-heading {
    font-weight: $font-weight-bold;
    color: $primary;

    @media (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }

  }

  .greet-name {
    color: $gray;
    font-size: $font-size-sm;
    text-transform: capitalize;

    @media (max-width: $sm-breakpoint) {
      font-size: .75rem;
    }
  }

  .greet-message {
    color: $black;
    font-size: .875rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: $sm-breakpoint) {
      text-align: center;
    }
  }

  .senderInfo-container {
    display: flex;
    justify-content: flex-end;

    @media (max-width: $sm-breakpoint) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .sender-info-tag,
    .sender-date-tag {
      .sender-info-name-tag {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        color: $primary;
        font-size: $font-size-sm;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $sm-breakpoint) {
          font-size: .75rem;
        }

        .avatar {
          width: 100%;
          height: 100%;
        }

      }

      .sender-info-heading {
        font-size: .75rem;
        color: $primary;
        text-transform: capitalize;
      }

      .sender-info,
      .sender-date {
        font-size: .75rem;
        color: $gray;
        text-transform: capitalize;

        @media (max-width: $sm-breakpoint) {
          font-size: .625rem;
        }
      }
    }
  }
}