.btn {
    width: 10rem !important;
    height: 3.125rem;
    text-decoration: none;
    text-transform: capitalize !important;
    font-size: $font-size-base;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s;
    @media (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
        max-width: 8rem;
        max-height: 3rem;
    }
}

.primarybtn{
    color: $white !important;
    background-color: $primary !important;
    font-size: $font-size-base;
    text-transform: capitalize !important;
    height: 3.125rem;
    max-width: 15rem;
    width: 100%;
    @media (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
        max-width: 25rem;
    }

}

.rounded-btn {
    border-radius: 3.125rem !important;
    border: 1px solid $white !important;
    color: $white !important;
}

.Back-btn {
    text-transform: capitalize !important;
    color: black !important;
    font-weight: bold !important;
  
    .back-icon {
        font-size: 12px !important;
        font-weight: $font-weight-medium !important;
    }
    p{
        font-weight: $font-weight-medium !important;
    }
}

.BlueOutline {
    border-radius: 3.125rem;
    border: 1px solid $primary;
    color: $primary;

    @media (max-width: $md-breakpoint) {
        border: 1px solid $white;
        color: $white;
    }

}

.logoutButton {
    width: 9.1875rem;
    height: 3.125rem;
    background-color: $light-red !important;
    text-transform: capitalize !important;
    color: $white !important;
    
    @media (max-width: $sm-breakpoint) {
        width: 3.5rem;
    }
    .logout-icon{
        font-size: $font-size-lg !important;
        color: $white;
        @media (max-width: $sm-breakpoint) {
            font-size: 22px;
        }
    }
    .MuiButton-startIcon {
        margin: 0 !important;
    }
}




.submitButton {
    background-color: $primary !important;
    color: $white !important;
    text-transform: capitalize !important;
    height: 3.125rem;
    width: 100%;
}


.sidebox-button {
    height: 50px;
    border: 1px solid $white;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;

    @media (max-width: $md-breakpoint) {
        font-size: $font-size-sm;
    }
}

.activeButton {
    background-color: $white;
    color: $primary;
}

.dialoge-button {
    max-width: 10rem;
    width: 100%;
    border: 1px solid $primary !important;
    text-transform: capitalize !important;
    padding: 0.5rem 1rem !important;
    border-radius: 3.125rem !important;

}

.outlined-dialoge-button {
    background-color: $white !important;
    color: $primary !important;
}

.filled-dialoge-button {
    background-color: $primary !important;
    color: $white !important;
}

.addGreetButton {
    width: 10.5rem;
    height: 3.125rem;
    background-color: $primary !important;
    text-transform: capitalize !important;
    color: $white !important;
    border-radius: 50px !important;
    z-index: 99;

    @media (max-width: $sm-breakpoint) {
        width: 4rem;
        height: 4rem;

        svg {
            font-size: 1.875rem !important;
        }
    }

    .MuiButton-startIcon {
        margin: 0 !important;
    }
}
