.password-security-accordion {
  box-shadow: none !important;
  border: 1px solid $field-background !important;
  background-color: $light-gray !important;
  border-radius: 0 !important;

  .accordion-heading {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    color: $black;

  }
  .accordion-paper {
    background-color: $white;
    padding: 2rem;
 
    @media (max-width: $sm-breakpoint) {
      padding: 1rem;
    }
  }
  
  .changepassword-heading,
  .deactivate-heading {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    color: $primary;
  
    @media (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  
  }
  
  .changepassword-content,
  .deactivate-content {
    color: $gray;
    font-size: $font-size-sm;
  }

}

