.drawer-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    .drawer-background {
        width: 100%;
        height: 22svh;
        background: $linear-background;
        padding: 2rem 1rem;
        position: relative;

        .sidebox-img-container {
            width: 6.5rem;
            height: 6.5rem;
            border: 1px solid white;
            padding: 0.5rem;
            border-radius: 50%;

            .profileImg {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .user-info {
            .userHeading {
                font-size: $font-size-base;
                color: $white;
                font-weight: $font-weight-medium;
            }

            .userSubheading {
                font-size: $font-size-sm;
                color: $white;
            }
        }

        .cross-icon {
            color: $white;
            position: absolute;
            top: 20px;
            right: 20px;
        }
    }

    .drawer-links {
        height: 70svh;
        width: 100%;
        padding: 1rem;

        .nav-link-small {
            display: flex;
            align-items: center;
            padding: 1rem;
            text-decoration: none;
            color: $black;
            font-weight: $font-weight-medium;
            border-bottom: 0.5px solid $field-background;

            .icon {
                color: $black;
                font-weight: 200 !important;
            }
        }

        .active {
            display: none;
        }
    }
}