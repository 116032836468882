.header {
    width: 100%;
    position: fixed;
    top: 0;
    height: $header-height;
    background: $linear-background;
    z-index: 999;

    @media (max-width: $sm-breakpoint) {
        height: $header-height-sm;
    }

    .header-container {
        .header-logo {
            width: 3rem;
            height: 3rem;

            .logo {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .menu-icon {
            color: $white;
            display: flex;
            padding: 0;
            justify-content: flex-start;
        }

        .nav-link {
            color: $white;
            font-size: $font-size-base;
            display: block;
            padding: 0.5rem;
            border-bottom: 1px solid transparent;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .active {
            display: none;
        }

    }
}

.organisation-header {
    width: 100%;
    height: $header-height-sm;
    background-color: $light-gray;
    top: $header-height;
    position: fixed;
    z-index: 999;

    @media (max-width: $sm-breakpoint) {
        top: $header-height-sm;
    }

    .organisationDetail-container {
        height: 3.125rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .organisation-name {
            height: 100%;
            width: 50%;
            font-size: $font-size-base;
            display: flex;
            align-items: center;

            @media (max-width: $sm-breakpoint) {
                width: 8.125rem;
                font-size: 0.75rem;
            }

            .organisationInfo {
                font-size: $font-size-base;
                font-weight: $font-weight-medium;

                @media (max-width: $sm-breakpoint) {
                    font-size: $font-size-sm;
                }
            }

            .side-line {
                height: 2.5rem;
                width: 0.25rem;
                background-color: $primary;
            }
        }

        .organisation-logo {
            width: 11.125rem;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media (max-width: $sm-breakpoint) {
                width: 8.125rem;
            }

            .logo {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}