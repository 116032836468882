.basicLoader {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, $primary);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), $primary 0);
    animation: spinner-zp9dbg 1s infinite linear;
  }

  @keyframes spinner-zp9dbg {
    to {
      transform: rotate(1turn);
    }
  }
}