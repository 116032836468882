.loginHeader {
    width: 100%;
    position: fixed;
    height: $header-height;
    background-color: transparent;
    z-index: 99;

    @media (max-width: $sm-breakpoint) {
        height: $header-height-sm;
    }



    .links-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .navlinks {
            text-decoration: none !important;
            color: $primary;
            margin-right: 1.5rem;
            padding: 0.5rem 1rem;
            font-size: $font-size-base;
            height: 100%;
            border-bottom: 2px solid transparent;
            transition: border-bottom 0.5s;

            &:hover {
                border-bottom-color: $primary;

                @media (max-width: $md-breakpoint) {
                    border-bottom-color: transparent;
                }
            }

            @media (max-width: $sm-breakpoint) {
                color: $white;
                font-size: $font-size-sm;
                margin-right: 1rem;
                padding: 0.5rem;
            }

            @media (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
                color: $white;
            }
        }
    }

}