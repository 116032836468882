.landingPage {
    position: relative;
    width: 100%;
    height: 100svh;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    overflow: hidden;

    .small-logo-bg {
        position: absolute;
        top: -10%;
        left: -6%;
        height: 60%;
        opacity: 8%;

        @media (max-width: $md-breakpoint) {
            display: none;
        }
    }

    .landingPage-content-container {
        height: 100svh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding-bottom: 5%;

        @media (max-width: $sm-breakpoint) {
            padding-bottom: 20%;
            align-items: center;
        }

        @media (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
            padding-bottom: 10%;
        }

        .logo-container {
            width: 16rem;
            height: 8rem;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: $sm-breakpoint) {
                width: 14rem;
                height: 7rem;
            }

            .logo {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .content {
            width: 90%;
            color: $white;
            font-size: $font-size-base;

            @media (max-width: $sm-breakpoint) {
                font-size: $font-size-sm;
                width: 100%;
                text-align: center;
            }
        }
    }
}