.greetPage {
  min-height: calc(100vh - ($header-height + $footer-height + $footer-height + 56px));
  height: 100%;
  margin-top: calc($header-height + $footer-height + 0.75rem) !important;

  @media (max-width: $sm-breakpoint) {
    min-height: calc(100vh - ($header-height-sm + $footer-height-sm + $footer-height-sm + 30px));
    margin-top: calc($header-height-sm + $footer-height + 0.75rem) !important;
  }

  .Tab-box {
    height: 100%;
    border: 1px solid $gray;
    padding: 1rem;
    border: 1px solid $field-background;

  }
}