.Profile{
  width: 100%;
  .profile-container{
     border: 1px solid $field-background;
     padding: 1rem;
    .profile-title{
      color: $light-red;
      font-weight: $font-weight-medium;
    }
    .profile-field{
      max-width:320px;
      width: 100%;
    }
  }
}