.hvr-forward {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    transition-duration: 0.3s;
    transition-property: transform;

    &:hover,
    &:focus,
    &:active {
        transform: translateX(8px);
    }
}

.animation_bounceToLeft {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    transition-property: color;
    transition-duration: 0.5s;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $white;
        transform: scaleX(0);
        transform-origin: 100% 50%;
        transition-property: transform;
        transition-duration: 0.5s;
        transition-timing-function: ease-out;
    }

    &:hover,
    &:focus,
    &:active {
        color: $primary !important;

        &:before {
            transform: scaleX(1);
            transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
        }
    }
}