/* style-lint-disable */

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$xs-breakpoint: 0px;
$sm-breakpoint: 600px;
$md-breakpoint: 900px;
$lg-breakpoint: 1200px;
$xl-breakpoint: 1600px;
$xxl-breakpoint: 2180px;

$grid-breakpoints: (
  xsBreakpoint: $xs-breakpoint,
  smBreakpoint: $sm-breakpoint,
  mdBreakpoint: $md-breakpoint,
  lgBreakpoint: $lg-breakpoint,
  xlBreakpoint: $xl-breakpoint,
);

$primary: #082755;
$secondary: #033B92;
$error: #c5291c;
$white: #ffffff;
$black: #000000;
$gray: #545454;
$success: #46962b;
$danger: #ff0000;
$warning: #ffc400;
$light-red: #990000;
$light-green: #A6C76C;
$light-gray: #F8F8F8;
$light-blue: #7DD2EA;
$dark-gray: #333333;
$field-background: #E2E2E2;
$linear-background: transparent linear-gradient(90deg, #033B92 0%, #082755 100%) 0% 0% no-repeat padding-box;

//Typography


$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: (
  $font-size-base * 1.125) !default; //18px
$font-size-sm: (
  $font-size-base * 0.875) !default; //14px

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: normal !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;

$h1-font-size: $font-size-base * 2.5 !default; //40px
$h2-font-size: $font-size-base * 2 !default; //32px
$h3-font-size: $font-size-base * 1.5 !default; //24px
$h4-font-size: $font-size-base !default; //16px
$h5-font-size: $font-size-base * 0.5 !default; //8px

$body1-font-size: $font-size-base !default; //16px
$body2-font-size: $font-size-base * 0.875 !default; //14px
$body3-font-size: $font-size-base * 0.75 !default; //12px

$btn-font-size: $font-size-base !default;

$h1-line-height: $font-size-base * 3.125 !default;
$h2-line-height: $font-size-base * 2.5 !default;
$h3-line-height: $font-size-base * 1.875 !default;
$h4-line-height: $font-size-base * 1.25 !default;
$h5-line-height: $font-size-base * 0.625 !default;

$body1-line-height: $font-size-base * 1.25 !default;
$body2-line-height: $font-size-base * 1.125 !default;
$body3-line-height: $font-size-base !default;

$headings-margin-bottom: 0 !default;
$headings-font-family: inherit !default;
$headings-font-weight: $font-weight-normal !default;
$headings-line-height: 1.5 !default;


$input-border-radius: 0.25rem;
$box-border-radius: 1rem;

// box-shadow
$box-shadow: 0rem 0.1875rem 0.375rem #00000029;

//header
$header-height : 5rem;
$header-height-sm : 4.5rem;

//footer
$footer-height : 5rem;
$footer-height-sm : 4.5rem;
